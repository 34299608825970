(function () {
    'use strict';

    angular.module('adminApp')
        .factory('AddEditAdminFeatureModal', AddEditAdminFeatureModal);

    AddEditAdminFeatureModal.$inject = ['$uibModal'];

    function AddEditAdminFeatureModal($uibModal) {

        function open(featureModel) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    featureModel: function () {
                        return featureModel;
                    }
                },
                templateUrl: "/static/templates/admin/modals/AddEditAdminFeatureModal.html",
                controller: AddEditAdminFeatureModalController
            });

            return modal.result;
        }

        return {
            open: open
        };
    }

    AddEditAdminFeatureModalController.$inject = ['$scope', '$uibModalInstance', 'Notification', 'featureModel',
        'aerosAdminApi'];

    function AddEditAdminFeatureModalController($scope, $uibModalInstance, Notification, featureModel, aerosAdminApi) {

        angular.extend($scope, {
            addFeature: addFeature,
            updateFeature: updateFeature
        });

        (function init() {
            $scope.originalFeatureModel = featureModel;
            $scope.featureModel = angular.copy(featureModel);
        })();

        function addFeature() {
            aerosAdminApi.featureApi.create($scope.featureModel).then(
                function(results) {
                	$uibModalInstance.close($scope.featureModel);
                    Notification.success('Feature successfully added.');
                }, function(error) {
                    Notification.error("Feature failed to add. " + error.data.message);
                }
            )
        }

        function updateFeature() {
            aerosAdminApi.featureApi.update($scope.featureModel).then(
                function(results) {
                	$uibModalInstance.close($scope.featureModel);
                    Notification.success('Feature successfully updated.');
                }, function(error) {
                    Notification.error("Feature failed to update. " + error.data.message);
                }
            )
        }

    }
})();